<template>
    <el-container>
        <el-main style="padding: 20px 0 20px 0;">
            <div>
                <div class="item">
                    <div class="head">用户信息</div>
                    <div class="main flex-between">
                        <div class="main-item">
                            <span class="label">昵称：</span>
                            <span class="contain">{{detail.nick_name}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">发布时间：</span>
                            <span class="contain">{{detail.created_at}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">状态：</span>
                            <span class="contain">{{detail.statusname}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">点赞人数：</span>
                            <span class="contain">{{detail.like_count}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">评论条数：</span>
                            <span class="contain">{{detail.comment_count}}</span>
                        </div>
                        <div class="main-item"></div>
                    </div>
                </div>
            
                <div class="item">
                    <div class="head">发布信息</div>
                    <div class="main">
                        <div class="main-item-m">
                            <span class="label">图片/视频：</span>
                            <span class="contain">
                                <block v-for="item in detail.content_list" :key="item.id">
                                    <img :src="viewContent(item.content)" v-if="item.content_type == 1">
                                    <video controls="controls" :src="viewContent(item.content)" v-else></video>
                                </block>
                            </span>
                        </div>
                        <div class="main-item-m">
                            <span class="label">发布文字：</span>
                            <span class="contain">{{detail.content ? detail.content : '    '}}</span>
                        </div>
                        <div class="main-item-m">
                            <span class="label">关联话题：</span>
                            <span class="contain">
                                <span class="view" @click="viewTopic">{{detail.topic_list | getTopicName}}</span>
                            </span>
                        </div>
                    </div>
                </div> 
            
                <div class="item">
                    <div class="head">关联商品</div>
                    <div class="main">
                        <el-table :data="detail.product_list" class="tb-table">
                            <el-table-column prop="product_name" label="商品名称"/>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <span class="view" @click="viewProductItem(scope.row.product_id)">查看详情</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>                
                
                <el-dialog title="关联话题" :visible.sync="dialogVisible" width="60%">
                    <div class="dialog-h">
                        <div class="item">
                            <span class="label"></span>
                            <span class="contain">
                                <el-input v-model="topicName"></el-input>
                            </span>
                        </div>
                        <el-button type="primary" @click="queryTopic">查询</el-button>
                    </div>
                    <div class="dialog-c">
                        <div v-if="topicList && topicList.length !== 0">
                            <el-table :data="topicList" class="tb-table">
                                <el-table-column label="话题标题">
                                    <template slot-scope="scope">
                                        <el-radio v-model="topicId" :label="scope.row.id"> {{scope.row.title}}</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="subtitle" label="话题简介"/>
                            </el-table>
                        </div>
                        <div class="no-info" v-else>
                            <i class="el-icon-ice-tea"></i>
                            <span>暂无数据</span>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="cancelDialog">取 消</el-button>
                        <el-button type="primary" @click="submitEdit">确认修改</el-button>
                        <el-button type="primary" @click="cancelTopic">取消关联</el-button>
                    </div>
                </el-dialog>
            </div>
        </el-main>
        <el-footer style="padding: 0;">
            <div style="display: flex; justify-content: flex-end; align-items: center;height: 56px;background:rgba(255,255,255,1);
box-shadow:0px -1px 2px 0px rgba(0,0,0,0.03);padding-right: 24px;">
                <el-button class="tb-button" @click="onCancel">取消</el-button>
                <el-button class="tb-button" type="primary" @click="offShelf" v-if="detail.status == 1">下架</el-button>
            </div>  
        </el-footer>
    </el-container>
</template>
<script>
export default {
    name:"FeedAudit",
    data(){
        return {
            detail:{},
            dialogVisible:false,
            topicList:[],
            topicName:"",
            topicId:""
        }
    },
    mounted() {
        this.id = this.$route.query.id * 1 || null
        if (this.id) {
            this.getDetail()
        }
    },
    methods:{
        async getDetail() {
            const url = `admin/feed/detail?id=${this.id}`
            let data = await this.$https.get(url)
            if (!data) return
            const statusname = ['无效', '有效']
            data.statusname = statusname[data.status]
            this.detail = data
            this.topicId = data.topic_id
        },
        viewContent(str){
            const strObj = JSON.parse(str);
            return strObj.url;
        },
        viewProductItem(id){
            this.$router.push(`/product/publish?id=${id}`)
        },
        viewTopic(){
            this.queryTopic();
            this.dialogVisible=true;
        },
        async queryTopic(){
            const url = `admin/feed/topic/list`
            const params = {
                page_no:0,
                page_size:20,
                title:this.topicName
            }
            const data = await this.$https.get(url, {params})
            this.topicList = data.list
        },
        offShelf(){
            this.$confirm('确认要下架这条内容吗?', '下架后小程序前端界面将不展示该条内容。', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const url = `admin/feed/modify/status`
                    this.$https.post(url, {id:this.id,status:0}).then(data => {
                        if (data) {
                            this.$message.success('下架成功！')
                            this.getDetail()
                        }
                    })
                    
                });
        },
        onCancel(){
            this.$router.push(`/feed/list`)
        },
        submitEdit(){
            const url = `admin/feed//modify/topic`
            let params = {
                id:this.id,
                topic_id: this.topicId
            }
            this.$https.post(url, params).then(data => {
                if (data) {
                    this.$message.success('修改成功');
                    this.cancelDialog();
                    this.getDetail()
                }
            })
        },
        cancelTopic(){
            const url = `admin/feed//modify/topic`
            let params = {
                id:this.id,
                topic_id: 0
            }
            this.$https.post(url, params).then(data => {
                if(data){
                    this.$message.success('取消成功');
                    this.cancelDialog();
                    this.getDetail()
                }
            })
        },
        cancelDialog(){
            this.dialogVisible=false;
        }
    },
    filters: {
        getTopicName(topicList) {
            let list = []
            if (topicList) {
                topicList.forEach(topic => {
                    list.push(topic.title)
                })
            }
            let result = list.join(',')
            if (!result) {
                return '关联话题'
            }
            return result
        }
    }
}
</script>
<style scoped lang="scss">
    .fix-head {
        padding-bottom: 24px;

        .title {
            padding-left: 16px;
        }

        .overview {
            text-align: right;

            &-item {
                flex-direction: column;

                .overview-label {
                    color: rgba(0, 0, 0, 0.45);
                    padding-bottom: 4px;
                }

                .overview-content {
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }

            .overview-item + .overview-item {
                margin-left: 40px;
            }
        }
    }

    .item {
        margin: 24px 24px 0;
        background-color: #fff;

        .head {
            padding: 16px 32px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid #E9E9E9;
            box-sizing: border-box;
        }

        .main {
            padding: 24px 32px;
            flex-wrap: wrap;
            &-item {
                width: 33%;
                margin-top: 16px;
                .label {
                    min-width: 70px;
                    color: rgba(0, 0, 0, 0.85);
                }
                .contain {
                    min-width: 300px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }
            
            // .main-item:nth-child(-n+3) {
            //     margin-top: 0;
            // }
            &-item-m{
                width: 100%;
                margin-top: 16px;
                min-height: 20px;
                .label {
                    min-width: 70px;
                    color: rgba(0, 0, 0, 0.85);
                    float: left;
                }
                .contain {
                    min-width: 300px;
                    color: rgba(0, 0, 0, 0.65);
                    img{
                        width: 200px;
                        height: 200px;
                        margin: 12px;
                    }
                    video{
                        width: 200px;
                        height: 200px;
                        margin: 12px;
                    }
                }
            }
            .view{
                color: #1890FF;
                cursor: pointer;
            }
        }
    }
    .dialog-h{
        width: 100%;
        overflow: hidden;
        position: relative;
        flex-wrap: wrap;
        padding: 10px 0;
        .item{
            width: 50%;
            float: left;
            margin: 0 16px 0 0;
            .label{
                width: 70px;
            }
            .contain {
                min-width: 300px;
            }
        }
    }
    .dialog-c{
        width: 100%;
            .no-info {
                text-align: center;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.25);
            }
    }
</style>